.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #77002e;
  color: white;
  border: 1px solid #77002e;
  margin: 0 1rem;
}

.btn:hover {
  background-color: #a50648;
  border-color: #9c1458;
}

.btn--alt {
  background-color: transparent;
  color: #800040;
}

.btn--alt:hover {
  background-color: #f8dae9;
}

.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  width: 40rem;
  z-index: 10;
  position: fixed;
  top: 20vh;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

audio {
  padding-left: 1em;
  padding-right: 1em;
}
