@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  background-color: #fcf3f6;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

.Card_card__28zbc {
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #77002e;
  color: white;
  border: 1px solid #77002e;
  margin: 0 1rem;
}

.btn:hover {
  background-color: #a50648;
  border-color: #9c1458;
}

.btn--alt {
  background-color: transparent;
  color: #800040;
}

.btn--alt:hover {
  background-color: #f8dae9;
}

.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  width: 40rem;
  z-index: 10;
  position: fixed;
  top: 20vh;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

audio {
  padding-left: 1em;
  padding-right: 1em;
}

#nprogress .bar {
  background: #ffcc33 !important;
  height: 5px important;
}

#nprogress .peg {
  box-shadow: 0 0 7px #ff0000, 0 0 3px #ff0000;
}

#nprogress .spinner-icon {
  width: 30px !important;
  height: 30px !important;
  border: solid 6px transparent !important;
  border-top-color: #1ba7e9d8 !important;
  border-left-color: #1ba7e9d8 !important;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select file";
  display: inline-block;
  background: -webkit-linear-gradient(top, #e4dada, #b8b4b4);
  border: 1px solid #999;
  border-radius: 4px;
  padding: 7px 4px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  cursor: pointer;
  text-shadow: 0.2px 0.2px rgb(0, 0, 0);
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
h4 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  font-size: 2.25rem;
  color: #11070e;
}

.NewMeetupForm_form__1x7oH {
  padding: 1rem;
}

.NewMeetupForm_control__26BUD {
  margin-bottom: 0.5rem;
}

.NewMeetupForm_control__26BUD label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.NewMeetupForm_control__26BUD input,
.NewMeetupForm_control__26BUD textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 100%;
}

.NewMeetupForm_actions__1rjEe {
  margin-top: 1rem;
  text-align: right;
}

.NewMeetupForm_actions__1rjEe button {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
}

.NewMeetupForm_actions__1rjEe button:hover,
.NewMeetupForm_actions__1rjEe button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.Layout_main__1drIs {
  margin: 3rem auto;
  width: 90%;
  max-width: 40rem;
}

.MainNavigation_header__2xsK2 {
  width: 100%;
  height: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #77002e;
  padding: 0 10%;
}

.MainNavigation_logo__NF0mD {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.MainNavigation_header__2xsK2 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.MainNavigation_header__2xsK2 li {
  margin-left: 3rem;
}

.MainNavigation_header__2xsK2 a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fcb8d2;
}

.MainNavigation_header__2xsK2 a:hover,
.MainNavigation_header__2xsK2 a:active,
.MainNavigation_header__2xsK2 a.MainNavigation_active__dVkgf {
  color: white;
}

.MainNavigation_badge__2EctL {
  background-color: #cc2062;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}

